<template>
  <div class="page page--reset">
    <h1>{{ $t('resetpassword.title') }}</h1>
    <template v-if="isValidSession">
      <div class="login__form" v-if="resetSuccess">
        <p class="success">{{ $t('resetpassword.success') }}</p>
        <p>{{ $t('backto') }}
          <router-link to="/login">Log in</router-link>
        </p>
      </div>
      <form action="" class="login__form" v-else>
        <div class="login__form__field">
          <label for="pass">{{ $t('login.password') }} {{ $t('register.minchars') }}</label>
          <input class="input login__input" id="pass" type="password"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="password" :placeholder="$t('login.password')"
                 autocomplete="new-password"/>
          <label for="pass2">{{ $t('login.password') }}</label>
          <input class="input login__input" id="pass2" type="password"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="password2" @keypress.enter="submitReset" :placeholder="$t('register.confirmpassword')"
                 autocomplete="new-password"/>
        </div>
        <button class="button"
                type="button"
                @click="submitReset">{{ $t('resetpassword.title') }}
        </button>
        <p class="error login__error"><span v-if="resetError">{{ $t(resetError) }}</span></p>
      </form>
    </template>
    <template v-else>
      <p class="error">{{ $t('resetpassword.invalid') }}</p>
      <p>{{ $t('backto') }}
        <router-link to="/login">Log in</router-link>
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      isValidSession: true,
      username: this.$route.query.username,
      code: this.$route.query.code,
      expired: false,

      password: "",
      password2: "",

      resetError: false,
      resetSuccess: false,
    };
  },

  created() {
    if (!this.username || !this.code) {
      this.$router.push('login');
      return;
    }
    this.checkValidSession();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    async checkValidSession() {
      const check = await this.$store.dispatch('user/checkPasswordResetValid', {
        username: this.username,
        code: this.code
      });
      this.isValidSession = check.status;
    },

    async submitReset() {
      this.resetError = false;
      if (!this.password || !this.password2 || this.password !== this.password2 || this.password.toString().length < 8) {
        this.resetError = 'resetpassword.invalidinput';
        return;
      }
      const response = await this.$store.dispatch('user/editPassword', {
        username: this.username,
        password: this.password,
        code: this.code
      });
      if (response.status) {
        this.resetSuccess = true;
        return;
      }
      this.resetError = 'resetpassword.error';
    }
  }
}
</script>